/* eslint-disable react/no-multi-comp */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-empty-function */
import React, {useState} from "react";
import Helper from "../../../shared/modules/Helper.js";
import MemberDiscountHelper from "../../../shared/modules/MemberDiscountHelper.js";
import ProductPanelImageOrCarousel from "./ProductPanelImageOrCarousel.jsx";
import PropTypes from "prop-types";
import RingSizeSelection from "./RingSizeSelection.jsx";
import SharedPropTypes from "../../Swap/modules/SharedPropTypes.js";
import Star from "../../../shared/components/Star.jsx";
import {connect} from "react-redux";
import {isMobile} from "../../../lib/userAgent.js";

const CarouselProductPanel = ({
  addWishListRequest,
  customerId,
  eligibleForFineJewelry,
  pdPrices,
  productDescriptor,
  productDescriptors,
  productPanelClass,
  removeWishListRequest,
  ringSizes,
  showClearancePrice,
  showComingSoon,
  showProductName,
  showRetailPrice,
  showTags,
  showWishlistStars,
  wishlisted
}) => {
  const [
    showingRingSelection,
    setShowingRingSelection
  ] = useState(false);

  const checkTag = (tag) => {
    if (tag === "Demi-Fine") return "demifine";
    if (tag === "Fine Jewelry") return "fine";
    return "";
  };

  const updateWishListRequests = (state) => {
    const {originalId} = productDescriptor;
    Object.values(state).forEach((value) => {
      const {id, wishlisted} = value;
      if (wishlisted) {
        addWishListRequest(id, id !== originalId);
      } else {
        removeWishListRequest(id, id !== originalId);
      }
    });
  };

  const queryParamBuilder = (currentQuery, newQuery) => {
    if (!newQuery || newQuery.length === 0) return currentQuery;
    return (/^\?/).test(currentQuery) ? `${currentQuery}&${newQuery}` : `?${newQuery}`;
  };

  const displayWishlistStar = (kindName, wishlisted) => {
    if (kindName === "Ring") {
      return ringSizes.filter(ring => ring.wishlisted).length > 0;
    }
    return wishlisted;
  };

  const shouldDisplaySlider = () => {
    const {"details": {"thumbnail_urls": thumbnailImages}} = productDescriptor;
    return thumbnailImages.length > 1 && isMobile();
  };

  const getClearancePrice = () => {
    const {"details": {"clearance_price": noCustomerClearancePrice}, id} = productDescriptor;
    const isCustomerPresent = customerId && customerId.length;
    const isClearancePriceAvailable = pdPrices[id] && pdPrices[id].clearance_price;

    if (isCustomerPresent && isClearancePriceAvailable) {
      return pdPrices[id].clearance_price;
    }
    return noCustomerClearancePrice;
  };

  const getMemberPrice = () => {
    const {
      id,
      "details": {
        "insider_price_dollars": insiderPriceDollars,
        "insider_price_dollars_varB": insiderPriceDollarsVarB,
        "insider_price_dollars_varC": insiderPriceDollarsVarC
      }
    } = productDescriptor;

    const insiderPriceMap = new Map([
      [
        "member_discount_var_b",
        insiderPriceDollarsVarB
      ],
      [
        "member_discount_var_c",
        insiderPriceDollarsVarC
      ]
    ]);

    if (pdPrices[id]) {
      return pdPrices[id].member_price;
    } else if (MemberDiscountHelper.shouldSeeMemberDiscountVariant()) {
      const memberVariant = MemberDiscountHelper.getMemberDiscountVariant();
      if (!memberVariant) return "";
      return insiderPriceMap.get(memberVariant);
    }
    return insiderPriceDollars;
  };

  const getPrice = () => {
    const {"details": {"retail_price": retailPrice}} = productDescriptor;
    return customerId ? getMemberPrice() : retailPrice;
  };

  const renderInnerContents = () => {
    const {
      "details": {
        designer,
        "first_date_received": firstDateReceived,
        "group_name": groupName,
        "retail_price": retailPrice
      }
    } = productDescriptor;

    return (
      <div className="row">
        <div className="styled_h6 designer-name">
          {designer}
        </div>
        {showProductName &&
          <div className="styled_h6 ph8 style-name">
            {groupName}
          </div>
        }
        {showClearancePrice && (
          <div className="styled_h6 price color-primary">
            {getClearancePrice()}
            {" "}
            <span className="strike">
              {`$${Helper.formatPriceCents(retailPrice)}`}
            </span>
          </div>
        )}
        {showRetailPrice && (
          <div className="styled_h6 price mtn mbn">
            {customerId ? "member " : ""}
            {getPrice()}
          </div>
        )}
        {showComingSoon && firstDateReceived === null &&
        <h8 className="coming-soon color-primary mt0">
Coming Soon!
        </h8>
        }
      </div>
    );
  };

  const {
    "details": {
      "is_fine": isFine,
      "kind_name": kindName,
      "insider_price_dollars": insiderPriceDollars,
      name,
      tag,
      url,
      wishlistable
    },
    id,
    sku
  } = productDescriptor;

  const addWishListCallback = () => {
    if (kindName === "Ring" && ringSizes[0]?.ring_size !== "Adjustable") {
      return () => setShowingRingSelection(true);
    }
    return addWishListRequest;
  };

  const removeWishListCallback = () => {
    if (kindName === "Ring" && ringSizes[0]?.ring_size !== "Adjustable") {
      return () => setShowingRingSelection(true);
    }
    return removeWishListRequest;
  };

  return (
    <div
      className={`col-xs-6 col-sm-4 pt0 ph0 pd-container ${productPanelClass}`}
      tabIndex="0"
    >
      {showingRingSelection && wishlistable && (!isFine || eligibleForFineJewelry) && (
        <div className="pd-container-contents ring-size-content">
          <RingSizeSelection
            customerId={customerId}
            hideRingSizeSelection={() => setShowingRingSelection(false)}
            ringSizes={ringSizes}
            updateWishListCallback={updateWishListRequests}
          />
        </div>
      )}
      {!showingRingSelection && (
        <div className="pd-container-contents">
          <div className={`row${shouldDisplaySlider() ? " pd-slider-contain" : " pd_image_contain"}`}>
            {showTags && tag &&
            <h8 className={`left ribbon-text most-requested ${checkTag(tag)}`}>
              {tag}
            </h8>
            }
            {showWishlistStars && wishlistable && (!isFine || eligibleForFineJewelry) && (
              <Star
                addWishListCallback={addWishListCallback}
                customerId={customerId}
                id={id}
                price={insiderPriceDollars}
                removeWishListCallback={removeWishListCallback}
                sku={sku}
                wishlisted={displayWishlistStar(kindName, wishlisted)}
              />
            )}
            <div>
              <ProductPanelImageOrCarousel
                handleShowSwapModal={() => {}}
                isGrid={false}
                isMobile={isMobile()}
                isSwapped={() => {}}
                previewSwapping={false}
                productDescriptor={productDescriptor}
                productDescriptors={productDescriptors}
                queryParamBuilder={queryParamBuilder}
                showClearancePrice={showClearancePrice}
                showModal={() => {}}
              />
            </div>
          </div>
          <a
            className="product-panel-link"
            href={url}
            title={name}
          >
            {renderInnerContents()}
          </a>
        </div>
      )}
    </div>
  );
};

CarouselProductPanel.propTypes = {
  "addWishListRequest": PropTypes.func.isRequired,
  "customerId": PropTypes.string,
  "eligibleForFineJewelry": PropTypes.bool.isRequired,
  "pdPrices": PropTypes.object,
  "productDescriptor": PropTypes.object.isRequired,
  // eslint-disable-next-line react/no-typos
  "productDescriptors": SharedPropTypes.productDescriptors,
  "productPanelClass": PropTypes.string,
  "removeWishListRequest": PropTypes.func.isRequired,
  "ringSizes": PropTypes.array,
  "showClearancePrice": PropTypes.bool.isRequired,
  "showComingSoon": PropTypes.bool.isRequired,
  "showProductName": PropTypes.bool.isRequired,
  "showRetailPrice": PropTypes.bool.isRequired,
  "showTags": PropTypes.bool.isRequired,
  "showWishlistStars": PropTypes.bool.isRequired,
  "wishlisted": PropTypes.bool.isRequired
};

CarouselProductPanel.defaultProps = {
  "customerId": "",
  "customerRequests": {},
  "eligibleForFineJewelry": false,
  "pdPrices": {},
  "productDescriptors": null,
  "productPanelClass": "",
  "ringSizes": []
};

const mapStoreToProps = store => ({
  "customerRequests": store.customerRequests,
  "pdPrices": store.pdPrices
});

export default connect(mapStoreToProps)(CarouselProductPanel);
