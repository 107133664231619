/* eslint-disable max-len */
/* eslint-disable no-empty-function */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-no-bind */

import "pure-react-carousel/dist/react-carousel.es.css";
import {ButtonFirst, CarouselProvider, Slide, Slider} from "pure-react-carousel";
import React, {useEffect, useState} from "react";
import CarouselContainer from "./CarouselContainer";
import CarouselProductPanel from "./CarouselProductPanel";
import EventsAPI from "../../../shared/modules/EventsAPI";
import PropTypes from "prop-types";
import {withCookies} from "react-cookie";
const fastlyImageCdnUrl = "https://karat-herokuapp-com.freetls.fastly.net/s3media";

const CarouselGrid = (props) => {
  const {
    addWishListRequest,
    cookies,
    customerRequests,
    eligibleForFineJewelry,
    getRingSizes,
    handleShowSwapModal,
    onSelectSwap,
    previewSwapping,
    productDescriptors,
    productPanelClass,
    products,
    removeWishListRequest,
    requestsFetched,
    showClearancePrice,
    showComingSoon,
    showModal,
    showProductName,
    showRetailPrice,
    showTags,
    showWishlistStars,
    useModal
  } = props;

  const [
    visibleSlides,
    setVisibleSlides
  ] = useState(0);

  const [
    currentSlide,
    setCurrentSlide
  ] = useState(0);

  const slidesToDisplay = {
    "640": 1.5,
    "760": 2.5,
    "800": 3.5,
    "default": 4.5
  };

  const getVisibleSlides = () => {
    if (window.innerWidth <= 640) {
      setVisibleSlides(slidesToDisplay["640"]);
    } else if (window.innerWidth < 760) {
      setVisibleSlides(slidesToDisplay["760"]);
    } else if (window.innerWidth < 800) {
      setVisibleSlides(slidesToDisplay["800"]);
    } else {
      setVisibleSlides(slidesToDisplay.default);
    }
  };

  const resetSlider = () => {
    document.getElementById("button-first").click();
  };

  const handleSlideBack = () => {
    setCurrentSlide(prevState => prevState - 1);
  };

  const handleSlideNext = () => {
    setCurrentSlide(prevState => prevState + 1);
  };
  const isBackButtonDisabled = () => currentSlide === 0;

  // checking for greater due to dynamic resizing from a smaller size to a larger size
  const isNextButtonDisabled = () => currentSlide >= (products.length - visibleSlides);

  const handleResize = () => {
    getVisibleSlides();
    resetSlider();
  };

  useEffect(() => {
    document.getElementById("carousel-grid-skeleton").classList.add("opacity-0", "display-none");
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    getVisibleSlides();
  }, []);

  const sendContactEvent = () => {
    EventsAPI.trackContactEvent(
      "clicked_on_shop_all_new",
      "from homepage",
      cookies.get("anonymous_contact_id")
    );
  };

  const renderProduct = (product, index) => {
    const details = product && product.details ? product.details : {};
    const wishlisted = requestsFetched && customerRequests[product.id] && customerRequests[product.id].statuses.includes("pending");

    return (
      <Slide
        aria-label={`${details && details.name} by ${details && details.designer}`}
        className="slide"
        index={index}
        // eslint-disable-next-line react/no-array-index-key
        key={String(product.id) + index}
      >
        <CarouselProductPanel
          addWishListRequest={addWishListRequest}
          customerId={null}
          eligibleForFineJewelry={eligibleForFineJewelry}
          handleShowSwapModal={handleShowSwapModal}
          onSelectSwap={onSelectSwap}
          previewSwapping={previewSwapping}
          productDescriptor={product}
          productDescriptors={productDescriptors}
          productPanelClass={productPanelClass}
          removeWishListRequest={removeWishListRequest}
          ringSizes={getRingSizes(product.id)}
          showClearancePrice={showClearancePrice}
          showComingSoon={showComingSoon}
          showModal={showModal}
          showProductName={showProductName}
          showRetailPrice={showRetailPrice}
          showTags={showTags}
          showWishlistStars={showWishlistStars}
          useModal={useModal}
          wishlisted={wishlisted || false}
        />
      </Slide>);
  };

  return (
    <CarouselContainer {...props}>
      <div
        className="flex flex-col gap-8"
        id="new-arrivals-carousel"
      >
        <div className="flex justify-between items-center">
          <a
            className="button-outlined-white"
            href="/jewelry?my_rocksbox-new_arrivals"
            onClick={sendContactEvent}
          >
            Shop All New
          </a>
          <div className="flex gap-2">
            <button
              aria-label="Back"
              // eslint-disable-next-line max-len
              className={`flex items-center justify-center size-10 rounded-full hover:bg-white/75 ${isBackButtonDisabled() ? "opacity-25" : ""}`}
              disabled={isBackButtonDisabled()}
              onClick={handleSlideBack}
              type="button"
            >
              <img
                src={`${fastlyImageCdnUrl}/site/homepage/2024/phase1/arrow-left.svg`}
              />
            </button>
            <button
              aria-label="Next"
              // eslint-disable-next-line max-len
              className={`flex items-center justify-center size-10 rounded-full hover:bg-white/75 ${isNextButtonDisabled() ? "opacity-25" : ""}`}
              disabled={isNextButtonDisabled()}
              onClick={handleSlideNext}
              type="button"
            >
              <img
                src={`${fastlyImageCdnUrl}/site/homepage/2024/phase1/arrow-right.svg`}
              />
            </button>
          </div>
        </div>
        <div className="lg:pr-0">
          <CarouselProvider
            className="overflow-hidden"
            currentSlide={currentSlide}
            id="carousel"
            isIntrinsicHeight
            step={visibleSlides}
            totalSlides={products.length}
            visibleSlides={visibleSlides}
          >
            <Slider classNameTray="flex gap-2">
              {products.map((product, index) => renderProduct(product, index))}
            </Slider>
            <ButtonFirst
              className="hidden"
              id="button-first"
            />
          </CarouselProvider>
        </div>
      </div>
    </CarouselContainer>
  );
};

CarouselGrid.propTypes = {
  "addWishListRequest": PropTypes.func.isRequired,
  "cookies": PropTypes.object.isRequired,
  "customerRequests": PropTypes.object.isRequired,
  "eligibleForFineJewelry": PropTypes.bool.isRequired,
  "getRingSizes": PropTypes.func.isRequired,
  "handleShowSwapModal": PropTypes.func.isRequired,
  "onSelectSwap": PropTypes.func.isRequired,
  "previewSwapping": PropTypes.bool.isRequired,
  "productDescriptors": PropTypes.array.isRequired,
  "productOrdering": PropTypes.array.isRequired,
  "productPanelClass": PropTypes.string.isRequired,
  "products": PropTypes.array.isRequired,
  "removeWishListRequest": PropTypes.func.isRequired,
  "requestsFetched": PropTypes.bool.isRequired,
  "showClearancePrice": PropTypes.bool.isRequired,
  "showComingSoon": PropTypes.bool.isRequired,
  "showModal": PropTypes.func.isRequired,
  "showProductName": PropTypes.bool.isRequired,
  "showRetailPrice": PropTypes.bool.isRequired,
  "showTags": PropTypes.bool.isRequired,
  "showWishlistStars": PropTypes.bool.isRequired,
  "useModal": PropTypes.bool.isRequired
};

CarouselGrid.defaultProps = {
  "addWishListRequest": () => {},
  "customerRequests": {},
  "eligibleForFineJewelry": false,
  "getRingSizes": () => {},
  "handleShowSwapModal": () => {},
  "onSelectSwap": () => {},
  "previewSwapping": false,
  "productDescriptors": [],
  "productOrdering": [],
  "productPanelClass": "",
  "products": [],
  "removeWishListRequest": () => {},
  "requestsFetched": false,
  "showClearancePrice": false,
  "showComingSoon": false,
  "showModal": () => {},
  "showProductName": false,
  "showRetailPrice": false,
  "showTags": false,
  "showWishlistStars": false,
  "useModal": false
};

export default withCookies(CarouselGrid);
