/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment, react/prop-types, react/require-optimization, react/no-multi-comp, react/display-name */

import {applyMiddleware, createStore} from "redux";
import ErrorBoundary from "../../../shared/components/ErrorBoundary";
import PropTypes from "prop-types";
import {Provider} from "react-redux";
import React from "react";
import {initializeStore} from "../../Rocksbox/components/stateInitializer";
import promise from "redux-promise";
import reducer from "../../../reducers";

const createStoreWithMiddleware = applyMiddleware(promise)(createStore);

const CarouselContainer = ({apiToken, customerId, params, renderCouponBanner, children}) => {
  const store = createStoreWithMiddleware(reducer, initializeStore({
    apiToken,
    customerId,
    params,
    renderCouponBanner
  }));

  return (
    <ErrorBoundary>
      <Provider store={store}>
        {React.cloneElement(children, children.props)}
      </Provider>
    </ErrorBoundary>
  );
};

CarouselContainer.propTypes = {
  "apiToken": PropTypes.string,
  "children": PropTypes.element.isRequired,
  "customerId": PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  "params": PropTypes.object.isRequired,
  "renderCouponBanner": PropTypes.bool
};

CarouselContainer.defaultProps = {
  "apiToken": null,
  "customerId": null,
  "renderCouponBanner": false
};

export default CarouselContainer;
